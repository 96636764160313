<template>
    <ContainerWithMenu :loading="loading">
        <div class="page-content">
			<div class="row">
				<div class="row-xs-2">
					<h1 class="h2 margin-bottom-20">Transazioni del {{this.filterEnabled.start_date.replace("00:00","")}}</h1>
				</div>
			</div>
			<TransactionTable :transactions = "transactions"/>
          <div class="text-center">
            <Pagination :page-count="pagesElements" :click-handler="paginateCallback"/>
          </div>
		</div>
    </ContainerWithMenu>
</template>

<script>
    import ContainerWithMenu from "../components/container/ContainerWithMenu";
    import TransactionTable from '../components/Transaction/TransactionTable'
    import {getTransactions} from "../api"
    import baseMixin from '../common/baseMixin'
    import Pagination from "../components/common/Pagination"

    export default {
      title: "Transazioni",
      name: "TransactionsDate",
      components: {ContainerWithMenu,TransactionTable,Pagination}, mixins: [baseMixin],

      data: function() {
            return {
                loading: false,
                transactions: {},
                filterEnabled: {
                    start_date: null,
                    end_date: null
                },
                date_filter: null,
            }
        },
        created() {
        },
        mounted(){
            this.date_filter =this.$route.query.transaction_date;
            if(this.date_filter != undefined){
                this.filterEnabled = {
                start_date: this.Utils.getDateStringFromISO(this.date_filter),
                end_date: this.Utils.getDateStringFromISO(this.date_filter.replace("00:00", "23:59"))
                }
                this.filterChanged(this.filterEnabled)
            }
        },
        methods: {
            paginateCallback(pageNum) {
              this.getTransactions(pageNum);
            },
            async getTransactions(page = 1,filter=this.filterEnabled) {
                this.transactions = {}
                this.loading = true;
                try {
                    let transactionsResponse = await getTransactions(page,filter);
                    this.transactions = transactionsResponse;
                    this.countElements = transactionsResponse.count;

                  this.loading = false;
                } catch (e) {
                    this.loading = false;
                    console.log(e)
                }
            },
            filterChanged(filter){
                this.filterEnabled = filter;
                this.getTransactions(1,filter)
            }
        }
    };
</script>
